<template>
  <div class="content">
    <div class="bgtop">
      <div class="topLeft">
        <img class="goUp"
             src="@/assets/interesing/退出@2x.png"
             @click="back"
             alt="">
        <span>单词本</span>
      </div>
    </div>
    <div class="moredetail">
      <div class="left-section">
        <div class="upper-box">
          <div class="text">
            <div style="font-size:20rem">
              今日学习（词）
              <div style="font-size:40rem;color:#333">{{this.detailList.data.has_do_amount}}</div>
            </div>
            <div style="font-size:20rem">
              本周学习（词）
              <div style="font-size:40rem;color:#333">{{this.detailList.data.rank_info.amount}}</div>
            </div>
          </div>
          <el-progress type="line"
                       :show-text="false"
                       :stroke-width="10"
                       style="width:204rem;transform: rotate(270deg);"
                       :percentage="this.accuracyData"></el-progress>
        </div>
        <div class="lower-box">
          <div class="shuju">
            累计数据
          </div>
          <div class="accuracyrate">
            本周正确率
            <el-progress :percentage="this.detailList.data.rank_info.accuracy"></el-progress>
          </div>
          <div class="ranking">
            本周正排名
            <el-progress :percentage="this.detailList.data.rank_info.rank"></el-progress>
          </div>
        </div>
      </div>
      <div class="right-section">
        <div class="answerbox">
          <div class="answer">记忆情况
          </div>
          <div class="zhengque">正确率{{(this.detailList.data.rank_info.accuracy *100).toFixed(1)}}%</div>
        </div>
        <div class="checktopic">
          <div :class="'checkbox'"
               v-for="(item, index) in topicListData"
               :key="index">
            <div v-if="!item.choiceResult">
              <el-skeleton animated />
            </div>
            <div v-else>
              <div :style="item.isTrue == false ? 'color:#FFA726;' : ''"
                   class="word"
                   v-show="item.choiceResult ? true : false">
                {{ item.word }}
              </div>
              <div class="content"
                   style=" font-size: 24px;
      font-weight: 400;
      color: #999999;
      line-height: 36rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
      padding:0 24rem 24rem 24rem;
     "
                   v-show="item.choiceResult ? true : false">

                {{item.translation}}
              </div>
            </div>
          </div>
        </div>
        <div class="annv">
          <div class="training"
               @click="toAnwser">继续记忆</div>
        </div>
      </div>
    </div>
    <div>

    </div>
  </div>
</template>
<script>
import { getRank, getWordDetial } from '@/api/Aaswer.js'
export default {
  data () {
    return {
      topicListData: [],
      rightNum: 0,
      errorNum: 0,
      accuracyData: 20,
      rankListData: [],
      detailList: {}
    }
  },
  async created () {
    this.topicListData = this.$route.query.topicListData
    console.log('res', this.topicListData[0].choiceResult)
    this.rightNum = this.$route.query.rightNum
    this.errorNum = this.$route.query.errorNum
    let userinfo = JSON.parse(window.localStorage.getItem('userInfo'))
    let userId = Number(userinfo.user_id)
    let subjectId = Number(this.$route.query.subjectId)
    const rankList = await getRank({
      uid: userId,
      subject_id: subjectId
    })
    this.rankListData = rankList.data.filter(item => item.subject_id == this.$route.query.subjectId)
    this.detailList = await getWordDetial({
      uid: userId,
      group_id: this.$route.query.group_id
    })
  },
  methods: {
    back () {
      this.$router.push({
        path: '/index',
      })
    },
    toAnwser () {
      this.$router.push({
        path: '/wordMemory',
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.content {
  cursor: pointer;
  .bgtop {
    width: 100%;
    height: 100rem;
    padding: 26rem 40rem;
    box-sizing: border-box;
    background: #2196f3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .topLeft {
      display: flex;
      justify-content: center;
      align-items: center;
      .goUp {
        width: 48rem;
        height: 48rem;
        margin-right: 24rem;
      }
      span {
        font-size: 28rem;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 42rem;
      }
    }
  }
  .moredetail {
    display: grid;
    grid-template-columns: 400rem 1fr; /* 左右两列等宽 */
    grid-gap: 16rem; /* 网格间距 */
    // height: 800rem;
    background-color: #f5f5f5;
    padding: 40rem;
    box-sizing: border-box;
    .left-section {
      display: flex;
      flex-direction: column;
      .upper-box {
        height: 50%;
        margin-bottom: 10rem;
        border-radius: 24rem;
        background-color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        // flex-wrap: wrap;
        .text {
          font-size: 14px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          padding-left: 32rem;
          width: 59%;
          color: #666666;
        }
      }
      .lower-box {
        height: 50%;
        border-radius: 24rem;
        background-color: #ffffff;
        padding: 32rem;
        box-sizing: border-box;
        .shuju {
          font-size: 28rem;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
        }
        .accuracyrate {
          margin-top: 40rem;
          margin-bottom: 24rem;
          font-size: 24rem;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
        }
        .ranking {
          margin-top: 40rem;
          margin-bottom: 24rem;
          font-size: 24rem;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
        }
      }
    }
    .right-section {
      width: 100%;
      border-radius: 24rem;
      background-color: #ffffff;
      padding: 32rem;
      box-sizing: border-box;
      .answerbox {
        display: flex;
        align-items: center;
        margin-bottom: 24rem;
        .answer {
          font-size: 28rem;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
        }
        .zhengque {
          font-size: 24rem;
          margin-left: 16rem;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
        }
      }
      .checktopic {
        width: 820rem;
        height: 580rem;
        display: flex;
        grid-template-columns: repeat(5, 1fr);
        grid-gap: 10rem;
        // justify-items: center; // 居中对齐每个单元格的内容
        // align-items: center;
        flex-direction: column;
        flex-wrap: wrap;
        margin-bottom: 64rem;
        .checkbox {
          display: flex;
          // justify-content: center;
          // align-items: center;
          flex-direction: column;
          width: 328rem;
          height: 192rem;
          background: #fafafa;
          border-radius: 16rem;
          font-size: 28rem;
          padding-top: 24rem;
          .word {
            margin-bottom: 16rem;
            padding-left: 24rem;
            // margin-left: -152rem;
          }
        }
        .error {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          width: 328rem;
          height: 228rem;
          color: #f2413a;
          border-radius: 16rem;
        }
      }
      .Comments {
        width: 1306rem;
        height: 100rem;
        line-height: 36rem;
        box-sizing: border-box;
        background: #fafafa;
        border-radius: 16rem;
        font-size: 24rem;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        margin-bottom: 64rem;
        position: relative;
        .shade {
          position: absolute;
          font-size: 56rem;
          font-family: YouSheBiaoTiHei-Regular, YouSheBiaoTiHei;
          font-weight: 600;
          line-height: 64rem;
          top: 0;
          left: 16rem;
          background-image: linear-gradient(
            to bottom,
            rgb(206, 206, 214),
            rgba(196, 187, 187, 0)
          ); /* 添加渐变背景色 */
          -webkit-background-clip: text; /* 使用背景色作为文本颜色的剪裁区域 */
          color: transparent; /* 将文本颜色设为透明 */
        }
        .context {
          font-size: 24rem;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          margin-left: 32rem;
          margin-top: 16rem;
          z-index: 999999;
          position: absolute;
        }
      }
      .annv {
        display: flex;
        justify-content: space-around;
        align-items: center;
        font-size: 28px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #2196f3;
        .parse {
          width: 630rem;
          height: 80rem;
          border-radius: 100rem;
          border: 1px solid #2196f3;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .training {
          width: 630rem;
          height: 80rem;
          border-radius: 100rem;
          background: #2196f3;
          color: #ffffff;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}
::v-deep .el-progress__text {
  color: #2196f3;
}
::v-deep .el-skeleton {
  width: 80%;
  padding: 24rem;
}
</style>